//Importação React
import React from "react";

export default function VantagensHome() {
  return (
    <>
      <section
        className="flex items-center justify-center py-20 mt-5"
        id="beneficios"
      >
        <div>
          <div className="flex items-center justify-center mb-8">
            <div>
              <h2 className="text-gray-800 font-nunito text-3xl md:text-4xl font-bold text-center">
                Veja as nossas soluções
              </h2>
              <p className="text-gray-600 font-roboto text-base md:text-lg font-normal text-center">
                Por que você deveria contratar nossos serviços?
              </p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col-reverse items-center">
            <picture className="mr-14 ">
              <img
                src="/images/dashboardVantagens.png"
                alt="Dashboard da Conta Energia"
                loading="lazy"
              />
            </picture>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-10 mx-6 md:mx-5 md:mr-10 2xl:pr-48 md:mb-0 mb-14 md:w-10/12 xl:w-2/3">
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <img
                  src="/images/icons/solucoes/oportunidade.svg"
                  alt="Icone de uma mão segurando uma lâmpada simbolizando a energia"
                  loading="lazy"
                  className="w-14 mb-5"
                />
                <div className="lg:ml-6 lg:block flex items-center justify-center flex-col">
                  <h4 className="text-xl font-bold font-nunito text-gray-700">
                    Geração de Energia
                  </h4>
                  <p className="font-roboto text-base text-gray-600 font-normal lg:text-left text-center mt-2">
                    Com alertas, notificações e sinalizações, iremos te
                    monitorar a sua geração de energia para você sempre manter a
                    economia.
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <img
                  src="/images/icons/solucoes/fatura.svg"
                  alt="Icone de Fatura"
                  loading="lazy"
                  className="w-14 mb-5"
                />
                <div className="lg:ml-6 lg:block flex items-center justify-center flex-col">
                  <h4 className="text-xl font-bold font-nunito text-gray-700">
                    Extrato de Faturas
                  </h4>
                  <p className="font-roboto text-base text-gray-600 font-normal lg:text-left text-center mt-2">
                    É possível visualizar um relatório consolidado do detalhe de
                    economia da fatura em especifico, quais são as penalidades,
                    a oportunidade de economia e indicadores relevantes.
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <img
                  src="/images/icons/solucoes/financeiro.svg"
                  alt="Icone de gráfico financeiro"
                  loading="lazy"
                  className="w-14 mb-5"
                />
                <div className="lg:ml-6 lg:block flex items-center justify-center flex-col">
                  <h4 className="text-xl font-bold font-nunito text-gray-700">
                    Financeiro
                  </h4>
                  <p className="font-roboto text-base text-gray-600 font-normal lg:text-left text-center mt-2">
                    Aqui também é possível informar a sitaução de suas faturas,
                    dizendo se foi paga ou não, qual a forma de pagamento e
                    outros. Dessa forma, tendo um controle total da gestão.
                  </p>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row items-center justify-center">
                <img
                  src="/images/icons/solucoes/oportunidade.svg"
                  alt="Icone de uma mão segurando uma lâmpada simbolizando a energia"
                  loading="lazy"
                  className="w-14 mb-5"
                />
                <div className="lg:ml-6 lg:block flex items-center justify-center flex-col">
                  <h4 className="text-xl font-bold font-nunito text-gray-700">
                    Ranking de Eficiência
                  </h4>
                  <p className="font-roboto text-base text-gray-600 font-normal lg:text-left text-center mt-2">
                    Aqui é possível fazer uma comparação de suas unidades
                    consumidoras e ter uma análise de eficiência entre elas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="flex items-center justify-center px-6 lg:px-14 2xl:px-48 py-20"
        id="beneficios"
      >
        <div>
          <div className="flex items-center justify-center mb-10">
            <div>
              <h2 className="text-gray-800 font-nunito text-3xl md:text-4xl font-bold text-center">
                Veja as nossas soluções
              </h2>
              <p className="text-gray-600 font-roboto text-base md:text-lg font-normal text-center">
                Por que você deveria contratar nossos serviços?
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-16 mx-6 md:mx-5 md:mb-0 mb-14">
            <div className="flex flex-col md:justify-start justify-center items-center md:items-start">
              <img
                src="/images/icons/solucoes/fatura.svg"
                alt="Icone de Fatura"
                loading="lazy"
                className="w-14 mb-5"
              />
              <div className="flex flex-col md:justify-start justify-center items-center md:items-start">
                <h4 className="text-xl font-bold font-nunito text-gray-700">
                  Extrato de Faturas
                </h4>
                <p className="font-roboto text-base text-gray-600 font-normal lg:text-left text-center mt-2">
                  Tenha os dados financeiros consolidados de suas faturas,
                  podendo visualizar a porcentagem de faturas a pagar, vencidas,
                  processadas e valor da fatura, valor pendente entre outros
                  dados.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:justify-start justify-center items-center md:items-start">
              <img
                src="/images/icons/solucoes/upload.svg"
                alt="Icone de upload de arquivos"
                loading="lazy"
                className="w-14 mb-5"
              />
              <div className="flex flex-col md:justify-start justify-center items-center md:items-start">
                <h4 className="text-xl font-bold font-nunito text-gray-700">
                  Gerenciador de Fatura
                </h4>
                <p className="font-roboto text-base text-gray-600 font-normal lg:text-left text-center mt-2">
                  Com o gerenciador de faturas, você vai poder identificar o
                  status de cada processamento e a origem do arquivo PDF das
                  faturas por cliente e UC.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:justify-start justify-center items-center md:items-start">
              <img
                src="/images/icons/solucoes/financeiro.svg"
                alt="Icone de gráfico financeiro"
                loading="lazy"
                className="w-14 mb-5"
              />
              <div className="flex flex-col md:justify-start justify-center items-center md:items-start">
                <h4 className="text-xl font-bold font-nunito text-gray-700">
                  Financeiro
                </h4>
                <p className="font-roboto text-base text-gray-600 font-normal lg:text-left text-center mt-2">
                  Tenha os dados de suas faturas e visualize a porcentagem de
                  faturas a pagar, vencidas, processadas, valor das faturas,
                  valor pendente, entre outros.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:justify-start justify-center items-center md:items-start">
              <img
                src="/images/icons/solucoes/oportunidade.svg"
                alt="Icone de uma mão segurando uma lâmpada simbolizando a energia"
                loading="lazy"
                className="w-14 mb-5"
              />
              <div className="flex flex-col md:justify-start justify-center items-center md:items-start">
                <h4 className="text-xl font-bold font-nunito text-gray-700">
                  Oportunidade Financeira
                </h4>
                <p className="font-roboto text-base text-gray-600 font-normal lg:text-left text-center mt-2">
                  Identificação de oportunidades financeiras e penalidades. É
                  possível filtrar por cliente, U.C ou mês.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
