// import components
// import react icons
//Importação React
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FiChevronDown, FiMenu, FiX } from "react-icons/fi";
import { motion, useAnimation } from "framer-motion";
import { boxVariant6, fadeInUp } from "./Variants.js";
import { useInView } from "react-intersection-observer";
import { Menu, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav() {
  const [header, setHeader] = useState(false);
  const [navMobile, setNavMobile] = useState(false);

  useEffect(() => {
    // scroll event
    window.addEventListener("scroll", () => {
      // set the header state according to scrollY position
      window.scrollY > 80 ? setHeader(true) : setHeader(false);
    });
  });

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <>
      <nav
        className={`${
          header ? "bg-[#FDE047] px-5 py-5 shadow-md" : "py-8 bg-[#FDE047]"
        }  fixed w-full left-0 right-0 top-0 z-20 transition-all duration-500 px-6 lg:px-14 2xl:px-48  `}
      >
        <div
          className={`${
            header ? "border-none" : "border-b border-gray-500 pb-5"
          } flex lg:block justify-between`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <NavLink to="/" className="mr-14">
                <img
                  src="/images/logo/logo_contaenergia.svg"
                  alt="Logo Conta Energia"
                  aria-label="Vá para tela inicial"
                  className="w-48"
                />
              </NavLink>
              <ul className="hidden lg:flex items-center space-x-14">
                <li>
                  <NavLink
                    to="/home"
                    activeStyle={{
                      color: "#111727",
                      fontWeight: 700
                    }}
                    className="font-nunito font-semibold text-lg text-gray-800 flex items-center"
                  >
                    Home
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    activeStyle={{
                      color: "#111727",
                      fontWeight: 700
                    }}
                    to="/sobre"
                    className="font-nunito font-semibold text-lg text-gray-800 flex items-center"
                  >
                    Sobre
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/pricing"
                    activeStyle={{
                      color: "#111727",
                      fontWeight: 700
                    }}
                    className="font-nunito font-semibold text-lg text-gray-800 flex items-center"
                  >
                    Preço
                  </NavLink>
                </li> */}
              </ul>
            </div>

            <div className="hidden lg:flex">
              <div className="space-x-2.5">
                <a
                  href="https://calendly.com/conta-energia/30min"
                  target="_blank"
                >
                  <button
                    onClick={() => setNavMobile(!navMobile)}
                    className="relative inline-flex group items-center justify-center py-2.5 px-6 bg-[#111727] rounded text-white text-sm font-nunito font-bold outline-none focus:ring-4 focus:ring-gray-400 transform active:scale-x-75 transition-transform hover:bg-zinc-800"
                  >
                    Agende uma apresentação
                  </button>
                </a>
                <a
                  href="https://calendly.com/conta-energia/30min"
                  target="_blank"
                >
                  <button
                    type="button"
                    className="relative inline-flex group py-1.5 px-4 rounded text-[#111727] bg-white border-2 border-[#111727] text-base font-nunito font-extrabold outline-none focus:ring-4 transform transition-transform hover:bg-gray-200 w-fit  "
                  >
                    Entrar
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            onClick={() => setNavMobile(!navMobile)}
            className="lg:hidden cursor-pointer "
          >
            {navMobile ? (
              <FiX className="text-3xl text-gray-600 " />
            ) : (
              <FiMenu className="text-3xl text-gray-600 " />
            )}
          </div>
          <motion.div
            ref={ref}
            variants={boxVariant6}
            initial="hidden"
            animate={control}
            className={`${
              navMobile ? "h-screen" : "max-h-0"
            } lg:hidden absolute  w-full left-0 top-0 overflow-hidden shadow-2xl transition-all duration-500 ease-out bg-white`}
          >
            <div className="flex flex-col w-full px-6 py-8 justify-between min-h-screen transition-all duration-500 ">
              <div className="flex items-center justify-between">
                <NavLink to="/">
                  <img
                    src="/images/logo/logo_contaenergia.svg"
                    alt="Logo Conta Energia"
                    aria-label="Vá para tela inicial"
                    className="w-6"
                  />
                </NavLink>
                <div
                  onClick={() => setNavMobile(!navMobile)}
                  className="lg:hidden cursor-pointer"
                >
                  {navMobile ? (
                    <FiX className="text-3xl text-gray-600 " />
                  ) : (
                    <FiMenu className="text-3xl text-gray-600 " />
                  )}
                </div>
              </div>
              <ul className="space-y-10 flex flex-col items-center justify-center flex-grow">
                <li>
                  <NavLink
                    to="/home"
                    activeStyle={{
                      color: "#eab308",
                      fontWeight: 700
                    }}
                    className="font-nunito font-semibold text-lg text-gray-800 flex items-center"
                  >
                    Home
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/sobre"
                    activeStyle={{
                      color: "#eab308",
                      fontWeight: 700
                    }}
                    className="font-nunito font-semibold text-lg text-gray-800 flex items-center"
                  >
                    Sobre
                  </NavLink>
                </li>
                <li>
                  {/* <NavLink
                    to="/pricing"
                    activeStyle={{
                      color: "#eab308",
                      fontWeight: 700
                    }}
                    className="font-nunito font-semibold text-lg text-gray-800 flex items-center"
                  >
                    Preço
                  </NavLink> */}
                </li>
              </ul>
              <div
                variants={fadeInUp}
                className="flex justify-center items-center "
              >
                <div className="space-x-2.5">
                  <a
                    href="https://calendly.com/conta-energia/30min"
                    target="_blank"
                  >
                    <button
                      onClick={() => setNavMobile(!navMobile)}
                      className="relative inline-flex group items-center justify-center py-2.5 px-6 bg-[#111727] rounded text-white text-sm font-nunito font-bold outline-none focus:ring-4 focus:ring-gray-400 transform active:scale-x-75 transition-transform hover:bg-zinc-800"
                    >
                      Agende uma apresentação
                    </button>
                  </a>
                  <a
                    href="https://calendly.com/conta-energia/30min"
                    target="_blank"
                  >
                    <button
                      type="button"
                      className="py-2.5 px-4 rounded text-[#111727] border-2 border-[#111727] text-base font-nunito font-extrabold outline-none focus:ring-4 shadow-lg transform transition-transform hover:bg-yellow-400 w-fit"
                    >
                      Entrar
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </nav>
    </>
  );
}
