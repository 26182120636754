//Importação React
import React from "react";
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/keyboard/keyboard.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/zoom/zoom.min.css";
import HeaderHome from "./HeaderHome";

export default function SwiperTeste() {
  return (
    <>
      <Swiper
        modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={swiper => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        <SwiperSlide>
          <HeaderHome />
        </SwiperSlide>
        <SwiperSlide>
          <HeaderHome />
        </SwiperSlide>
        <SwiperSlide>
          <HeaderHome />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
