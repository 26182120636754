import React, { lazy, Suspense, useEffect } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import "../../utils/scroll.css";
import Nav from "../../components/Nav";
import VantagensHome from "./components/VantagensHome";
import SwiperTeste from "./components/SwiperTeste";

const HeaderHome = lazy(() => import("./components/HeaderHome"));
const NumerosHome = lazy(() => import("./components/NumerosHome"));
const Footer = lazy(() => import("../../components/Footer"));

export default function Home() {
  useEffect(() => {
    document.title = "Faça a gestão de energia do seu negócio | Conta Energia";
  }, []);

  return (
    <Suspense>
      <div className="scrollbar h-screen">
        <ScrollToTop />
        <div className="mb-24">
          <Nav />
        </div>
        {/* <HeaderHome /> */}
        <SwiperTeste />
        <NumerosHome />
        <VantagensHome />
        <Footer />
      </div>
    </Suspense>
  );
}
