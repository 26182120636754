//Importação React
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import YouTube from "react-youtube";

export default function HeaderHome() {
  const opts = {
    height: "340",
    width: "570",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0
    }
  };

  const [exibirModal, setExibirModal] = useState(false);

  return (
    <header
      className="px-6 lg:px-14 2xl:px-48 grid grid-cols-1 md:grid-cols-5 gap-6 items-center justify-center py-20 bg-[#FDE047] "
      id="inicio"
    >
      <div className="col-span-2">
        <div className="mb-5 lg:mb-8 flex lg:block flex-col items-center">
          <h1 className="text-5xl md:text-6xl font-extrabold font-nunito text-[#111727] lg:leading-tight mb-2.5 text-center lg:text-left">
            <span className="text-white font-black"> Economia fácil </span>
            <br /> e inteligente na sua gestão de energia
          </h1>
          <p className="text-gray-800 font-roboto text-base lg:text-lg  text-center lg:text-left">
            No nosso software de energia, temos soluções para você não precisar
            se preocupar com a gestão e medição de energia, deixe conosco.
          </p>
        </div>
        <div className="flex items-center md:justify-start justify-center gap-3 mb-5">
          <a href="https://calendly.com/conta-energia/30min" target="_blank">
            <button
              type="button"
              className=" inline-flex group items-center justify-center py-2.5 px-4 bg-[#111727] rounded text-white text-base font-nunito font-extrabold outline-none focus:ring-4 focus:ring-gray-400 shadow-lg transform transition-transform hover:bg-zinc-800 w-fit"
            >
              Marque uma reunião
            </button>
          </a>
          <NavLink
            to="/pricing"
            className="py-2.5 px-4 rounded bg-white text-[#111727] border-2 border-[#111727] text-base font-nunito font-extrabold outline-none focus:ring-4 shadow-lg transform transition-transform hover:bg-gray-200 w-fit"
          >
            Adquira agora
          </NavLink>
        </div>
      </div>
      <div className="col-span-3">
        <img
          src="/images/dashboard.svg"
          alt="Dashboard"
          className=" bg-cover "
        />
      </div>
      {/* <div className="overflow-hidden pb-[56.25%] relative h-0">
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/cp4kWvgrNUQ"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          className="left-0 top-0 h-full w-full absolute"
        />
      </div> */}
      {/* <div>
        {" "}
        <button onClick={() => setExibirModal(!exibirModal)}>
          Ver modal
        </button>{" "}
      </div>
      <div
        className={!exibirModal ? "hidden" : "" + " relative z-10"}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity h-screen w-full"
          onClick={() => setExibirModal(!exibirModal)}
        ></div>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex  items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8  sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-10 w-10 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="mt-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </header>
  );
}
