export const fadeInDown = {
  initial: {
    y: -70,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.7 }
  },
  visible: { opacity: 1, y: 0, transition: { duration: 1.3 } },
  hidden: { opacity: 1, y: 100 }
};

export const fadeInDown2 = {
  initial: {
    y: -70,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.7 }
  },
  visible: { opacity: 1, y: 0, transition: { duration: 1.6 } },
  hidden: { opacity: 1, y: 100 }
};

export const fadeInDown3 = {
  initial: {
    y: -70,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.7 }
  },
  visible: { opacity: 1, y: 0, transition: { duration: 1.9 } },
  hidden: { opacity: 1, y: 100 }
};

export const fadeInDown4 = {
  initial: {
    y: -70,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.7 }
  },
  visible: { opacity: 1, y: 0, transition: { duration: 2.1 } },
  hidden: { opacity: 1, y: 100 }
};

export const fadeInUp = {
  initial: {
    y: 70,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { type: "tween", duration: 1.1 }
  },
  visible: { opacity: 1, y: 0, transition: { duration: 1.3 } },
  hidden: { opacity: 1, y: -100 }
};

export const fadeInUp2 = {
  initial: {
    y: 70,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { type: "tween", duration: 1.7 }
  },
  visible: { opacity: 1, y: 0, transition: { duration: 1.7 } },
  hidden: { opacity: 1, y: -100 }
};

export const fadeInUp3 = {
  initial: {
    y: 70,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { type: "tween", duration: 1.7 }
  },
  visible: { opacity: 1, y: 0, transition: { duration: 2.0 } },
  hidden: { opacity: 1, y: -100 }
};

export const fadeInLeft = {
  initial: {
    x: 70,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.5 }
  }
};

export const fadeInRight = {
  initial: {
    x: -70,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.5 }
  },
  visible: { opacity: 1, x: 0, transition: { duration: 1.0 } },
  hidden: { opacity: 1, x: -100 }
};

export const fadeInRight2 = {
  initial: {
    x: -70,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.5 }
  },
  visible: { opacity: 1, x: 0, transition: { duration: 1.3 } },
  hidden: { opacity: 1, x: -100 }
};

export const fadeInRight3 = {
  initial: {
    x: -70,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.5 }
  },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 } },
  hidden: { opacity: 1, x: -100 }
};

export const fadeInRight4 = {
  initial: {
    x: -70,
    opacity: 0
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.5 }
  },
  visible: { opacity: 1, x: 0, transition: { duration: 1.7 } },
  hidden: { opacity: 1, x: -100 }
};

export const boxVariant = {
  visible: {
    opacity: 1,
    scale: 1,
    y: -70,
    transition: { type: "tween", duration: 0.7 }
  },
  initial: {
    y: 70,
    opacity: 1
  },
  hidden: { opacity: 0, scale: 0 }
};

export const boxVariant2 = {
  visible: {
    opacity: 1,
    scale: 1,
    y: 30,
    transition: { type: "tween", duration: 1.1 }
  },
  initial: {
    y: -70,
    opacity: 1
  },
  hidden: { opacity: 0, scale: 1 }
};

export const boxVariant3 = {
  visible: {
    opacity: 1,
    scale: 1,
    y: -70,
    transition: { type: "tween", duration: 1.1 }
  },
  initial: {
    y: 70,
    opacity: 1
  },
  hidden: { opacity: 0, scale: 1 }
};

export const boxVariant4 = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: "tween", duration: 1.1 }
  },
  initial: {
    opacity: 1
  },
  hidden: { opacity: 0, scale: 1 }
};

export const boxVariant5 = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: "tween", duration: 1.1 }
  },
  initial: {
    opacity: 1
  },
  hidden: { opacity: 1, scale: 1 }
};

export const boxVariant6 = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: "tween", duration: 1.1 }
  },
  initial: {
    opacity: 1
  },
  hidden: { opacity: 0, scale: 0 }
};
